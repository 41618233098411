











































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import Confirmation from '@/components/modals/Confirmation.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerRolesModule from '@/store/modules/manager/roles'
import { RoleResource, RoleStore } from '@/store/types'
import { getHalfsOfList } from '@/utils/functions'

@Component({
  components: {
    Confirmation,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class PermissionsForm extends Mixins(NotifyMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ default: null })
  private role!: RoleResource

  @Prop({ default: false })
  private disabled!: boolean

  private get permissions () {
    return ManagerRolesModule.permissions
  }

  private form: RoleStore = {
    permissions: [],
    title: '',
  }
  private getHalfsOfList = getHalfsOfList

  private mounted () {
    if (this.role) {
      this.form = {
        ...this.form,
        permissions: [...this.role.permissions],
        title: this.role.title,
      }
    }
  }

  @Debounce(300)
  @Bind
  private handleSave () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          ManagerRolesModule.saveRole({
            params: this.form,
            roleUUID: this.role ? this.role.uuid : undefined,
          })
            .then((response: RoleResource) => {
              requestAnimationFrame(() => (form.reset()))
              this.notifySuccess('Данные сохранены')
              this.$emit('success', response)
            })
            .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private handleDelete () {
    ManagerRolesModule.deleteRole(this.role.uuid)
      .then(() => {
        this.notifySuccess('Группа прав удалена')
        this.$router.push({ name: 'manager.users.roles' })
      })
      .catch(this.notifyError)
  }

  private async confirmDelete () {
    this.confirm.open(
      'Удаление группы прав',
      'Вы уверены, что хотите удалить группу прав?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(this.handleDelete)
      .catch(() => {return})
  }
}
