import { unionBy, without } from 'lodash'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  ITableOptions,
  ManagerRolesGetRequest,
  ManagerRolesPermissionsGetRequest,
  ManagerRolesPostRequest,
  ManagerRolesUuidDeleteRequest,
  ManagerRolesUuidGetRequest,
  ManagerRolesUuidPatchRequest,
  ManagerRolesUuidUsersGetRequest,
  ManagerRolesUuidUsersPostRequest,
  RoleGroupResource,
  RoleResource,
  RoleShortResource,
  RoleStore,
  UserShortResource,
} from '@/store/types'

/**
 * Работа с группами доступа системы
 * - получения дерева прав доступа
 * - получение списка групп прав доступа
 * - CRUD + управление пользователями группы прав доступа
 */

@Module({
  dynamic: true,
  name: 'ManagerRoles',
  namespaced: true,
  store,
})
class ManagerRoles extends VuexModule {
  // ---------------------------- Permissions tree ---------------------------- >>

  permissions: RoleGroupResource[] = []

  @Mutation
  setPermissions (payload: RoleGroupResource[]) {
    this.permissions = [...payload]
  }

  @Action({ rawError: true })
  async fetchPermissions () {
    if (!this.permissions.length) {
      const { data } = await ManagerRolesPermissionsGetRequest()
      this.setPermissions(data)
      return data
    }

    return this.roles
  }

  // ---------------------------- Roles ---------------------------- >>
  // Filter
  rolesFilter: ITableOptions = GET_DEFAULT_TABLE_FILTER()

  @Mutation
  setRolesFilter (payload: ITableOptions) {
    this.rolesFilter = Object.assign({}, payload)
  }

  // Entities
  roles: RoleShortResource[] = []

  @Mutation
  setRoles (payload: RoleShortResource[]) {
    this.roles = [...payload]
  }

  @Action({ rawError: true })
  async fetchRoles () {
    const { data } = await ManagerRolesGetRequest()

    this.setRoles(data)

    return data
  }

  // ---------------------------- Role ---------------------------- >>

  role: RoleResource | null = null

  @Mutation
  setRole (payload: RoleResource) {
    this.role = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchRole (roleUUID: string) {
    const { data } = await ManagerRolesUuidGetRequest(roleUUID)
    this.setRole(data)
    return data
  }

  // ---------------------------- Role manage ---------------------------- >>

  @Action({ rawError: true })
  async saveRole (payload: { roleUUID?: string, params: RoleStore }) {
    if (payload.roleUUID) {
      const { data } = await ManagerRolesUuidPatchRequest(payload.roleUUID, payload.params)
      this.setRole(data)
      return data
    } else {
      const { data } = await ManagerRolesPostRequest(payload.params)
      this.setRole(data)
      return data
    }
  }

  @Action({ rawError: true })
  async deleteRole (roleUUID: string) {
    await ManagerRolesUuidDeleteRequest(roleUUID)
  }

  // ---------------------------- Role users ---------------------------- >>

  users: UserShortResource[] = []

  @Mutation
  setRoleUsers (payload: UserShortResource[]) {
    this.users = [...payload]
  }

  @Action({ rawError: true })
  async fetchRoleUsers (roleUUID: string) {
    const { data } = await ManagerRolesUuidUsersGetRequest(roleUUID)
    this.setRoleUsers(data)
    return data
  }

  @Action({ rawError: true })
  async updateRoleUsers (payload: { roleUUID: string, users: UserShortResource[], action: 'increment' | 'decrement' }) {
    const newUsers = payload.action === 'increment'
      ? unionBy(this.users, payload.users, 'uuid')
      : without(this.users, ...payload.users)

    const { data } = await ManagerRolesUuidUsersPostRequest(payload.roleUUID, {
      userIds: newUsers.map((user: UserShortResource) => user.id),
    })
    this.setRoleUsers(data)
    return data
  }
}

const ManagerRolesModule = getModule(ManagerRoles)

export default ManagerRolesModule
