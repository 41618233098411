




import { Vue, Component, Prop } from 'vue-property-decorator'

import PermissionsForm from '@/components/forms/role/PermissionsForm.vue'
import { RoleResource } from '@/store/types'

@Component({
  components: {
    PermissionsForm,
  },
})
export default class RolesItemPermissions extends Vue {
  @Prop({ required: true })
  private role!: RoleResource

  private disabledEdit = process.env.VUE_APP_ROLES_FORM_ENABLE === '0'
}
